import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/galidog-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "galidog-v2-by-troispointzéro"
    }}>{`Galidog V2 (by `}<a parentName="h1" {...{
        "href": "https://www.troispointzero.fr/"
      }}>{`TroisPointZéro`}</a>{`)`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Auteurs : `}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Elisa MELLERET | Chargée de projet chez TroisPointZéro | `}<a parentName="li" {...{
          "href": "mailto:elisa@tpz.fr"
        }}>{`elisa@tpz.fr`}</a></li>
      <li parentName="ul">{`Vincent BOUYSSOU | Président fondateur de TroisPointZéro | `}<a parentName="li" {...{
          "href": "mailto:vincent@tpz.fr"
        }}>{`vincent@tpz.fr`}</a></li>
      <li parentName="ul">{`Jordan PROTIN | Développeur informatique chez TroisPointZéro | `}<a parentName="li" {...{
          "href": "mailto:jordan@tpz.fr"
        }}>{`jordan@tpz.fr`}</a></li>
      <li parentName="ul">{`James RICHARD | CTO chez TroisPointZéro | `}<a parentName="li" {...{
          "href": "mailto:vincent@tpz.fr"
        }}>{`vincent@tpz.fr`}</a></li>
      <li parentName="ul">{`Thibaud SEVERINI | Chef de projet chez T'innov | `}<a parentName="li" {...{
          "href": "mailto:ts@t-innov.fr"
        }}>{`ts@t-innov.fr`}</a></li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Dernière mise à jour de cette documentation : lundi 20 mars 2023`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Dernière version stable de l'application mobile : `}<strong parentName="p">{`1.1.8`}</strong></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Pour un problème de droits d'accès ou pour toutes autres questions, veuillez nous contacter !`}</p>
    </blockquote>
    <h2 {...{
      "id": "à-propos"
    }}>{`À propos`}</h2>
    <p>{`Galidog est un projet qui a été réalisé pour répondre aux besoins d'une association. Le but de cette dernière est d'accompagner les personnes déficientes visuelles (qu'on appellera « DV »).`}</p>
    <p>{`Ce projet regroupe 2 solutions numériques :`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitlab.tpzdev.fr/projets/clients/galidog/galidog-v2/galidog-v2-frontend"
        }}>{`Une application mobile`}</a>{` (côté front-end) : réalisée à l'aide du framework `}<a parentName="li" {...{
          "href": "https://reactnative.dev/"
        }}>{`React-Native`}</a>{` v0.61.5`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitlab.tpzdev.fr/projets/clients/galidog/galidog-v2/galidog-v2-backend"
        }}>{`Une application web servant de back-office et d'API REST`}</a>{` (côté back-end) : réalisée à l'aide du framework `}<a parentName="li" {...{
          "href": "https://symfony.com/"
        }}>{`Symfony`}</a>{` v5.0`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Ces « repositories » sont actuellement hébergés sur le serveur gitlab privé de `}<a parentName="p" {...{
          "href": "https://www.troispointzero.fr/"
        }}>{`TroisPointZéro`}</a>{` ! Une intégration continue est également mise en place sur ces projets afin de déployer les solutions automatiquement.`}</p>
    </blockquote>
    <h2 {...{
      "id": "hébergement-des-solutions-numériques-du-projet"
    }}>{`Hébergement des solutions numériques du projet`}</h2>
    <h3 {...{
      "id": "application-mobile"
    }}>{`Application mobile`}</h3>
    <p>{`L'application mobile étant destinée à une association, celle-ci `}<strong parentName="p">{`ne sera PAS`}</strong>{` disponible au grand public et sera donc inaccessible depuis le Google Play Store.
Nous transmettons de cette façon directement l'APK stable de l'application aux clients finaux.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Les utilisateurs finaux seront munis des smartphones Android : Xiaomi Mi 8 !`}</p>
    </blockquote>
    <h3 {...{
      "id": "back-office"
    }}>{`Back-office`}</h3>
    <p>{`Le back-office de l'application mobile du projet permet d'avoir une vision d'ensemble des principales actions que chaque utilisateur effectue depuis l'application mobile (création de trajet, informations sur les comptes utilisateurs, etc.).
Ce back-office intégre également la partie `}<a parentName="p" {...{
        "href": "http://api.galidog.org/api"
      }}>{`API REST`}</a>{`, permettant de délivrer des ressources que l'application mobile consommera.`}</p>
    <blockquote>
      <p parentName="blockquote">{`L'interface du back-office est actuellement disponible à l'adresse web suivante : `}<a parentName="p" {...{
          "href": "http://api.galidog.org/"
        }}>{`http://api.galidog.org/`}</a></p>
    </blockquote>
    <p>{`L'ensemble de cette solution numérique (BO + API) est hosté sur un serveur OVH. `}</p>
    <h3 {...{
      "id": "cette-documentation"
    }}>{`Cette documentation`}</h3>
    <p>{`Cette documentation a été réalisée à l'aide de `}<a parentName="p" {...{
        "href": "https://www.docz.site/"
      }}>{`Docz`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Les sources de cette documentation sont situées `}<a parentName="p" {...{
          "href": "https://gitlab.tpzdev.fr/projets/clients/galidog/galidog-docs"
        }}>{`dans ce repository`}</a>{` !`}</p>
    </blockquote>
    <p>{`Les fichiers statiques générés pour construire cette documentation sont hébergés grâce au `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/hosting"
      }}>{`hosting de Firebase`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Le compte Firebase du projet permet d'héberger cette documentation et d'utiliser la solution `}<a parentName="p" {...{
          "href": "/tools-crashlytics"
        }}>{`Crashlytics`}</a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      